import { SlideView, NavigationCardView } from './components';
import { StyledMainLayout } from './Main.styles';

export const Main = () => {
  return (
    <StyledMainLayout>
      <SlideView />
      <NavigationCardView />
    </StyledMainLayout>
  );
};
