import type { ChartData, CoreScaleOptions, Scale, TooltipItem } from 'chart.js';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import type { Point } from 'chart.js/dist/core/core.controller';
import { useCallback, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { cssLineChartContainerStyle } from './LineChart.styles';

interface LineChartProps {
  labels: string[];
  chartData: {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor?: string;
  }[];
  suffix?: string;
  style?: { aspectRatio?: number };
}

export const LineChart = ({
  labels,
  chartData,
  suffix,
  style,
}: LineChartProps) => {
  const convertData = useCallback(
    (data?: number | null | Point | string) => {
      if (typeof data === 'number') {
        if (suffix === '%') return data.toFixed(2) + suffix;
        else return data.toLocaleString() + (suffix || '');
      } else {
        return '-';
      }
    },
    [suffix]
  );

  const data: ChartData<'line', number[] | undefined, string> = useMemo(() => {
    return {
      labels: labels,
      datasets: chartData.map((data) => ({
        type: 'line',
        label: data.label,
        borderColor: data.borderColor,
        backgroundColor: data.backgroundColor || data.borderColor,
        borderWidth: 2,
        data: data.data,
        tension: 0.5, // smooth 처리
        spanGaps: true, // 빠진 데이터가 있을 경우 양옆 연결해 이어 그림
      })),
    };
  }, [chartData, labels]);

  const options = useMemo(() => {
    return {
      maintainAspectRatio: true,
      aspectRatio: style?.aspectRatio, // 화면 너비 반응형
      interaction: {
        mode: 'index' as const,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: '#ffffff',
          padding: {
            top: 12,
            left: 16,
            right: 16,
            bottom: 12,
          },
          titleColor: '#333333',
          cornerRadius: 5,
          caretSize: 0,
          titleFont: {
            size: 14,
            family: 'Pretendard',
            weight: 500,
          },
          bodyFont: {
            size: 14,
            family: 'Pretendard',
            weight: 500,
          },
          titleAlign: 'left' as const,
          bodyAlign: 'left' as const,
          titleMarginBottom: 12,
          borderWidth: 1,
          borderColor: '#F2F2F2',
          boxPadding: 6,
          bodySpacing: 8,
          callbacks: {
            labelColor: function (context: TooltipItem<'line'>) {
              return {
                borderColor: '#ffffff',
                backgroundColor: String(context.dataset.borderColor),
                borderWidth: 4,
                borderRadius: 0,
              };
            },
            labelTextColor: function () {
              return '#333333';
            },
            label: function (context: TooltipItem<'line'>) {
              return (
                (context.dataset.label || '') +
                ': ' +
                convertData(context.dataset.data[context.dataIndex])
              );
            }, // tooltip 에 단위 표기
          },
        },
      },
      scales: {
        x: {
          grid: { display: false },
        },
        y: {
          min: 0,
          grid: { display: false },
          ticks: {
            precision: 0, // 소수값 삭제
            callback: function (
              this: Scale<CoreScaleOptions>,
              tickValue: string | number
            ) {
              return convertData(tickValue);
            },
          },
        },
      },
    };
  }, [convertData, style?.aspectRatio]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  return (
    <div css={cssLineChartContainerStyle(style)}>
      <Line data={data} options={options} />
    </div>
  );
};
