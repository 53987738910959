import styled from '@emotion/styled';
import { MEDIA } from '@storelink-io/storelink-ui/styles/GlobalStyleVariables';

export const StyledNavigationCard = styled.div`
  width: 300px;
  height: 320px;
  background: #ffffff;
  box-shadow: 1px 1px 12px rgba(16, 48, 69, 0.12);
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .info-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    word-break: keep-all;
    span {
      line-height: 24px;
    }
  }
  .button-container {
    text-align: right;
    .ant-btn {
      padding: 8px 24px;
      height: max-content;
      :hover {
        span {
          color: #70baff;
          transition-duration: 0.3s;
        }
      }
    }
  }
  ${MEDIA.md} {
    width: 100%;
  }
`;
