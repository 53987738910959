import { css } from '@emotion/react';

export const cssBarChartContainerStyle = (style?: { aspectRatio?: number }) =>
  css`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    aspect-ratio: ${style?.aspectRatio};
  `;
