import { StorelinkText } from '@storelink-io/storelink-ui/base';
import { Carousel } from 'antd';
import { useMemo } from 'react';
import {
  StyledSlideBoxLayout,
  StyledSlideViewLayout,
} from './SlideView.styles';

export const SlideView = () => {
  const slideItems = useMemo(() => {
    return [
      {
        content: (
          <>
            <StorelinkText kind={34} weight="bold" color="gray1">
              믿고 맡길 수 있는 광고 집행,
            </StorelinkText>
            <StorelinkText kind={34} weight="bold" color="gray1">
              <span className="adlink">애드링크</span>와 함께 간단하게
              해결하세요!
            </StorelinkText>
          </>
        ),
        imgUrl: 'assets/images/adlink-moniter.png',
        imgWidth: '537px',
        imgHeight: '366px',
      },
      {
        content: (
          <>
            <StorelinkText kind={34} weight="regular" color="gray1">
              해당 업체와 실제 계약한{' '}
              <StorelinkText kind={34} weight="bold" color="gray1">
                광고주들이 작성한 리뷰
              </StorelinkText>
              로
            </StorelinkText>
            <StorelinkText kind={34} weight="regular" color="gray1">
              업체에 대한 평가를 확인하고{' '}
              <span className="highlight">우수 업체</span>를 선택하세요!
            </StorelinkText>
          </>
        ),
        imgUrl: 'assets/images/corporate-review.png',
        imgWidth: '458px',
        imgHeight: '391px',
      },
      {
        content: (
          <>
            <StorelinkText kind={34} weight="regular" color="gray1">
              계약 전,{' '}
              <StorelinkText kind={34} weight="bold" color="gray1">
                다양한 광고사기사례를 확인
              </StorelinkText>
              하고
            </StorelinkText>
            <StorelinkText kind={34} weight="regular" color="gray1">
              광고업체와 더 <span className="highlight">안전하게 계약</span>
              하세요!
            </StorelinkText>
          </>
        ),
        imgUrl: 'assets/images/anti-froud.png',
        imgWidth: '444px',
        imgHeight: '412px',
      },

      {
        content: (
          <>
            <StorelinkText kind={34} color="gray1" weight="regular">
              애드링크가 제공하는{' '}
              <span className="highlight">편리한 광고성과분석</span>으로
            </StorelinkText>
            <StorelinkText kind={34} color="gray1" weight="regular">
              <StorelinkText kind={34} color="gray1" weight="bold">
                내 계약의 성과를 확인
              </StorelinkText>
              하세요!
            </StorelinkText>
          </>
        ),
        imgUrl: 'assets/images/ad-performance.png',
        imgWidth: '458px',
        imgHeight: '368px',
      },
    ];
  }, []);

  return (
    <StyledSlideViewLayout>
      <Carousel autoplay>
        {slideItems.map((item) => (
          <div key={item.imgUrl}>
            <StyledSlideBoxLayout
              key={item.imgUrl}
              className="main-slide-box"
              imgUrl={item.imgUrl}
              imgWidth={item.imgWidth}
              imgHeight={item.imgHeight}
            >
              {item.content}
            </StyledSlideBoxLayout>
          </div>
        ))}
      </Carousel>
    </StyledSlideViewLayout>
  );
};
