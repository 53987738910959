import { css } from '@emotion/react';

export const cssLineChartContainerStyle = (style?: {
  aspectRatio?: number;
}) => css`
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: ${style?.aspectRatio};
  display: flex;
  direction: column;
  align-items: center;
  justify-content: center;
`;
