import { AntdButton, StorelinkText } from '@storelink-io/storelink-ui/base';
import { useRouter } from 'next/router';
import { StyledNavigationCard } from './NavigationCard.styles';

interface INavigationCardProps {
  title: string;
  url: string;
}
export const NavigationCard = ({
  title,
  url,
  children,
}: React.PropsWithChildren<INavigationCardProps>) => {
  const router = useRouter();
  return (
    <StyledNavigationCard>
      <div className="info-container">
        <StorelinkText kind="h4" weight="bold">
          {title}
        </StorelinkText>
        <StorelinkText kind="p1" weight="regular">
          {children}
        </StorelinkText>
      </div>
      <div className="button-container">
        <AntdButton type="default" onClick={() => router.push(url)}>
          <StorelinkText color="gray1" kind="p1" weight="bold">
            바로가기
          </StorelinkText>
        </AntdButton>
      </div>
    </StyledNavigationCard>
  );
};
