import styled from '@emotion/styled';
import { MEDIA } from '@storelink-io/storelink-ui/styles/GlobalStyleVariables';

export const StyledNavigationCardView = styled.div`
  background: #f0f8fd;
  .navigation-card-content-box {
    width: 1024px;
    margin: 0 auto;
    padding: 120px 0;

    display: flex;
    flex-direction: column;
    gap: 64px;

    span {
      letter-spacing: -0.03em;
    }

    .navigation-card-list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 8px;
    }
    ${MEDIA.md} {
      width: 100%;
      padding: 120px 16px;
      .navigation-card-list {
        flex-direction: column;
      }
    }
  }
`;
