import styled from '@emotion/styled';
import { MEDIA } from '@storelink-io/storelink-ui/styles/GlobalStyleVariables';

export const StyledSlideViewLayout = styled.div`
  .slick-dots {
    button {
      background-color: black !important;
    }
  }
`;

type StyledSlideBoxLayoutProps = {
  imgUrl: string;
  imgWidth: string;
  imgHeight: string;
};

export const StyledSlideBoxLayout = styled.div<StyledSlideBoxLayoutProps>`
  height: 640px;
  width: 1024px;
  margin: 0 auto;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  background-image: url(${(props) => props.imgUrl});
  background-repeat: no-repeat;
  background-size: ${(props) => props.imgWidth} ${(props) => props.imgHeight};
  background-position: 100% 60%;
  span {
    letter-spacing: -0.03em;
    user-select: none;

    .adlink {
      color: #459af7;
    }
    .highlight {
      color: #eab33e;
      font-weight: 700;
    }
  }
  .slick-dots {
    button {
      background-color: black !important;
    }
  }
  ${MEDIA.md} {
    width: 100%;
    padding: 0 16px;
  }
`;
