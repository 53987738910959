import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

type Props = {
  title?: string;
  children?: never;
};

export const NotFoundPage: FC<Props> = (props) => {
  const { t } = useTranslation(['system']);

  const title = props.title ?? t('system:notFound.title');

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <div>
        <h1 data-testid="not-found-title">{title}</h1>
        <p>
          <a href={'/'}>{t('system:links.backToHome')}</a>
        </p>
      </div>
    </>
  );
};
