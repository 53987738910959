import type { ChartData, CoreScaleOptions, Scale, TooltipItem } from 'chart.js';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { cssBarChartContainerStyle } from './BarChart.styles';

interface BarChartProps {
  labels: string[];
  chartData: {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor?: string;
    borderRadius?: number;
    categoryPercentage?: number;
  }[];
  suffix?: string;
  style?: { aspectRatio?: number };
}
export const BarChart = ({
  labels,
  chartData,
  suffix,
  style,
}: BarChartProps) => {
  const data: ChartData<'bar', number[], string> = useMemo(() => {
    return {
      labels: labels,
      datasets: chartData.map((data) => ({
        type: 'bar',
        label: data.label,
        borderColor: data.borderColor,
        backgroundColor: data.backgroundColor || data.borderColor,
        borderWidth: 0,
        categoryPercentage: data.categoryPercentage,
        data: data.data,
        borderRadius: data.borderRadius,
      })),
    };
  }, [chartData, labels]);

  const options = useMemo(() => {
    return {
      maintainAspectRatio: true,
      aspectRatio: style?.aspectRatio, // 화면 너비 반응형
      interaction: {
        mode: 'index' as const,
      },
      plugins: {
        legend: {
          position: 'bottom' as const,
        },
        tooltip: {
          backgroundColor: '#ffffff',
          padding: {
            top: 12,
            left: 16,
            right: 16,
            bottom: 12,
          },
          titleColor: '#333333',
          cornerRadius: 5,
          caretSize: 0,
          titleFont: {
            size: 14,
            family: 'Pretendard',
            weight: 500,
          },
          bodyFont: {
            size: 14,
            family: 'Pretendard',
            weight: 500,
          },
          titleAlign: 'left' as const,
          bodyAlign: 'left' as const,
          titleMarginBottom: 12,
          borderColor: '#F2F2F2',
          boxPadding: 6,
          bodySpacing: 8,
          callbacks: {
            labelColor: function (context: TooltipItem<'bar'>) {
              return {
                borderColor: '#ffffff',
                backgroundColor: String(context.dataset.borderColor),
                borderWidth: 4,
                borderRadius: 0,
              };
            },
            labelTextColor: function () {
              return '#333333';
            },
            label: function (context: TooltipItem<'bar'>) {
              return `${context.dataset.label || ''}: ${
                typeof context.raw === 'number'
                  ? context.raw.toLocaleString() + (suffix || '')
                  : '-'
              }`;
            },
          },
        },
      },
      scales: {
        x: {
          grid: { display: false },
        },
        y: {
          min: 0,
          grid: { display: false },
          ticks: {
            precision: 0, // 소수값 삭제
            callback: function (
              this: Scale<CoreScaleOptions>,
              tickValue: number | string
            ) {
              return tickValue.toLocaleString() + (suffix || '');
            },
          },
        },
      },
    };
  }, [style, suffix]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  return (
    <div css={cssBarChartContainerStyle(style)}>
      <Bar data={data} options={options} />
    </div>
  );
};
