import type { GetStaticProps, InferGetStaticPropsType } from 'next';
import { NextSeo } from 'next-seo';
import { MainLayout } from '@/components';
import { Main } from '@/container/main';
import { getServerSideTranslations } from '@/lib/i18n';

export default function IndexPage(
  _props: InferGetStaticPropsType<typeof getStaticProps>
) {
  return (
    <>
      <NextSeo title="메인페이지" />
      <MainLayout fullWidth>
        <Main />
      </MainLayout>
    </>
  );
}

export const getStaticProps: GetStaticProps = async (context) => {
  const { locale } = context;
  if (locale === undefined) {
    throw new Error('locale is missing');
  }

  return {
    props: {
      ...(await getServerSideTranslations(locale, ['common'])),
    },
  };
};
