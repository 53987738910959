import { StorelinkText } from '@storelink-io/storelink-ui/base';
import { useMemo } from 'react';
import { pageRoutes } from 'routes/pageRoutes';
import { NavigationCard } from './NavigationCard';
import { StyledNavigationCardView } from './NavigationCardView.styles';

export const NavigationCardView = () => {
  const navigationCardItems = useMemo(() => {
    return [
      {
        title: '광고업체 DB',
        url: pageRoutes.advCorporate.main.path,
        content: (
          <>
            광고업체의 상세 정보를 열람하고,
            <br />
            손쉽게 연락하여 마케팅 캠페인을
            <br />
            진행해보세요!
          </>
        ),
      },
      {
        title: '광고주의 후기',
        url: pageRoutes.advCorporate.main.path,
        content: (
          <>
            광고업체 DB에서 광고업체를
            <br />
            열람하고, 후기를 참고하여
            <br />
            마케팅 예산을 신중하게
            <br /> 결정하세요!
          </>
        ),
      },
      {
        title: '광고사기 예방',
        url: pageRoutes.fraud.main.path,
        content: (
          <>
            또 다른 피해를 막기위해
            <br />
            함께 만들어나가는
            <br />
            광고 사기 사례 DB,
            <br />
            광고 계약 전 확인하세요!
          </>
        ),
      },
      {
        title: '광고성과 분석',
        url: pageRoutes.performance.main.path,
        content: (
          <>
            애드링크의 광고성과분석
            <br />
            템플릿을 통해
            <br />
            나의 마케팅 캠페인이
            <br />
            실질적으로 효과가 있었는지
            <br />
            직접 확인해보세요!
          </>
        ),
      },
    ];
  }, []);

  return (
    <StyledNavigationCardView>
      <div className="navigation-card-content-box">
        <StorelinkText kind={36} color="gray1" weight="regular">
          광고 대행이 막막하다면!{' '}
          <StorelinkText kind={36} color="gray1" weight="bold">
            애드링크
          </StorelinkText>
        </StorelinkText>
        <div className="navigation-card-list">
          {navigationCardItems.map((item) => (
            <NavigationCard key={item.title} title={item.title} url={item.url}>
              {item.content}
            </NavigationCard>
          ))}
        </div>
      </div>
    </StyledNavigationCardView>
  );
};
